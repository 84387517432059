/* You can add global styles to this file, and also import other style files */

:root {
    --color-primary: #2b58cd;
    --color-success: #10c56e;
    --color-danger: #ff4d4f;
    --color-dark-danger: #922d2e;
    --color-warning: #f0a300;
    --color-grey: #7f7f7f;
    --color-light: #bdc3c7;
    --color-text-header: #0d1a3e;
    --color-text-primary: #2d3447;
    --color-text-secondary: #5a627a;
    --color-text-disabled: #c0c4d0;
    --background-default: #fbfbfb;
    --background-module: #f7f7f7;
    --background-page: #bdc3c7;
    --background-disable: #ecf0f1;
    --background-selected: #81ecec;
    --border-color: #e0e2e7;
    --shadow-color: rgba(16, 33, 77, 0.3);
}

@font-face {
    font-family: Reeji;
    src: url(./assets/fonts/REEJI-ZhenyanGB2.0-GX.ttf) format("truetype");
}

.color-primary {
    color: var(--color-primary);
}

.background-primary {
    background-color: var(--color-primary);
}

.color-success {
    color: var(--color-success);
}

.background-success {
    background-color: var(--color-success);
}

.color-danger {
    color: var(--color-danger) !important;
}

.background-danger {
    background-color: var(--color-danger);
}

.color-dark-danger {
    color: var(--color-dark-danger);
}

.background-dark-danger {
    background-color: var(--color-dark-danger);
}

.color-warning {
    color: var(--color-warning);
}

.background-warning {
    background-color: var(--color-warning);
}

.color-grey {
    color: var(--color-grey);
}

.background-grey {
    background-color: var(--color-grey);
}

.color-light {
    color: var(--color-light);
}

.background-light {
    background-color: var(--color-light) !important;
}

.color-text-header {
    color: var(--color-text-header);
}

.color-text-primary {
    color: var(--color-text-primary);
}

.color-text-secondary {
    color: var(--color-text-secondary);
}

.color-text-disabled {
    color: var(--color-text-disabled);
}

.color-white {
    color: white;
}

.background-white {
    background-color: white !important;
}

.background-default {
    background-color: var(--background-default);
}

.background-module {
    background-color: var(--background-module);
}

.background-page {
    background-color: var(--background-page);
}

.border-color {
    border-color: var(--border-color);
}

.shadow-color {
    box-shadow: 0 0 10px var(--shadow-color);
}

.background-selected {
    background-color: var(--background-selected);
}

.grid {
    display: flex;
    flex-direction: column;
    border-spacing: 0;
    border-collapse: collapse;
    letter-spacing: 0;
    word-spacing: 0;
    .row {
        display: flex;
        flex-direction: row;
        .column {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            position: relative;
        }
    }
    .text-left {
        justify-content: left !important;
    }
    .text-right {
        justify-content: right !important;
    }
}

.sticky {
    position: sticky !important;
    z-index: 20;
}

.clickable {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 3px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label {
    margin: 0;
}

*:disabled {
    cursor: not-allowed;
}

.cdk-overlay-container {
    z-index: 2000;
}

.user-popover {
    width: 400px;
    padding: 0 !important;
    .ant-popover-inner-content {
        padding: 0 !important;
    }
    .ant-popover-arrow {
        display: none;
    }
    .ant-cascader {
        width: 100%;
    }
}

.user-menu {
    .ant-cascader-menu-item-expand-icon {
        display: none;
    }
}